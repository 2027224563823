import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';

class Dev extends React.Component {

    state = {
        open: false,
        data: {
            Roles: [],
            AccessRights: [],
            Components: [],
            Ministry: []
        }
    }

    componentDidMount() {
        this.getdata()
    }

    getdata() {
        fetch('/Account/GetUserProperties')
            .then(result => result.json())
            .then(data => this.setState({ data }));
    }

    update() {
        fetch('/Account/SetUserProperties', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.data)
        })
            .then(result => result.json())
            .then(data => this.setState({ data }))
    }

    revert() {
        this.getdata()
    }

    close = () => this.setState({ open: false });

    handleKeys(e) {
        if (e.key === '|') {
            this.setState({ open: true })
        }
    }

    changeData(e) {
        const { data } = this.state;
        data[e.target.name] = data[e.target.name] || [];
        data[e.target.name][0] = e.target.value;
        this.setState({ data });
    }

    render() {
        return (
            <>
                {/* <div>
                <div id="keyboard"
                    contentEditable={true}
                    onKeyUp={this.handleKeys.bind(this)}
                    style={{ position: "fixed", top: 0, width: "1000px", height: "20px", zIndex: 99999, opacity: 0 }}>
                </div>
                <Dialog open={true} onClose={this.close.bind(this)}>
                    <DialogTitle>
                        Dev Tools
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Roles"
                            name="Roles"
                            value={(this.state.data ? this.state.data.Roles : [""])[0] || ""}
                            onChange={this.changeData.bind(this)}
                        />
                        <TextField
                            label="AccessRights"
                            name="AccessRights"
                            value={(this.state.data ? this.state.data.AccessRights : [""])[0] || ""}
                            onChange={this.changeData.bind(this)}
                        />
                        <TextField
                            label="Ministry"
                            name="Ministry"
                            value={(this.state.data ? this.state.data.Ministry : [""])[0] || ""}
                            onChange={this.changeData.bind(this)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.update.bind(this)}>ISSEJVJA</Button>
                        <Button onClick={this.revert.bind(this)}>ĦASSAR</Button>
                    </DialogActions>
                </Dialog>
            </div> */}
            </>
        );
    }
}

export default Dev;